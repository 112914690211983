export const AUTH_INPUTS = {
  inputs: [
    { required: true, show: true, id: 'email', name: 'Email *', placeholder: 'Введите Email', type: 'email', grid: '1 / 11' },
    { required: true, show: true, id: 'password', name: 'Пароль *', placeholder: 'Введите пароль', type: 'password', grid: '1 / 11' },
  ],
  data: {
    email: '',
    password: ''
  }
}

export const REGISTRATION_INPUTS = {
  inputs: [
    { required: true, show: true, id: 'email', name: 'Email *', placeholder: 'Введите Email', type: 'email', grid: '1 / 11' },
    { required: true, show: true, id: 'name', name: 'ФИО *', placeholder: 'Введите ФИО', type: 'text', grid: '1 / 11' },
    { required: true, show: true, id: 'password', name: 'Пароль *', placeholder: 'Введите пароль', type: 'password', grid: '1 / 11' },
    { required: false, show: true, id: 'referral', name: 'Реферальный код', placeholder: 'Реферальный код (если есть)', type: 'text', grid: '1 / 11' },
  ],
  data: {
    email: '',
    name: '',
    password: '',
    referral: '',
    partner: false,
  }
}

export const RESET_INPUTS = {
  inputs: [
    { required: false, id: 'email', name: 'Email', placeholder: 'Введите Email', type: 'email', grid: '1 / 11', show: true },
    { required: false, id: 'resetCode', name: 'Код', placeholder: 'Введите код', type: 'text', grid: '1 / 11', show: false },
    { required: false, id: 'password', name: 'Пароль', placeholder: 'Введите новый пароль', type: 'password', grid: '1 / 11', show: false },
    { required: false, id: 'repeat_password', name: 'Повторите пароль', placeholder: 'Повторите новый пароль', type: 'password', grid: '1 / 11', show: false },
  ],
  data: {
    email: '',
    resetCode: '',
    password: '',
    repeat_password: '',
  }
}