
import { defineComponent, PropType } from "vue-demi";
import { mask } from "vue-the-mask";
import { Modal } from "@/interfaces/modal/modal.dto";
import { DownArrowIcon } from '@/plugins/icons';

export default defineComponent({
  emits: ['inputHandler', 'toggleDrop', 'selectDropElement'],
  name: 'CommonInputs',
  directives: {
    mask
  },
  props: {
    input: {
      type: Object as PropType<Modal.Input>,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    dropElementName(element: { name: string }) {
      return element.name;
    },
  },
  components: {
    DownArrowIcon
  }
});
