import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6fc2e3f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "input"
}
const _hoisted_3 = ["type", "placeholder"]
const _hoisted_4 = ["type", "placeholder"]
const _hoisted_5 = ["type", "placeholder"]
const _hoisted_6 = {
  key: 0,
  class: "drop"
}
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_down_arrow_icon = _resolveComponent("down-arrow-icon")!
  const _directive_mask = _resolveDirective("mask")!

  return (_ctx.input.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "common-input",
        style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`)
      }, [
        (_ctx.input.name)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.input.name), 1))
          : _createCommentVNode("", true),
        (_ctx.input.disabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.data[_ctx.input.id]), 1))
          : (_ctx.input.mask)
            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 2,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event)),
                type: _ctx.input.type,
                placeholder: _ctx.input.placeholder,
                onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('inputHandler')), ["enter"]))
              }, null, 40, _hoisted_3)), [
                [_directive_mask, _ctx.input.mask],
                [_vModelDynamic, _ctx.data[_ctx.input.id]]
              ])
            : (_ctx.input.drop)
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 3,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event)),
                  class: _normalizeClass(["dropping", { active: _ctx.input.showDrop }]),
                  type: _ctx.input.type,
                  placeholder: _ctx.input.placeholder,
                  onKeyup: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.inputKeyUp && _ctx.inputKeyUp(...args))),
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('toggleDrop', _ctx.input)))
                }, null, 42, _hoisted_4)), [
                  [_vModelDynamic, _ctx.data[_ctx.input.id]]
                ])
              : _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 4,
                  type: _ctx.input.type,
                  placeholder: _ctx.input.placeholder,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event)),
                  onKeyup: _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.$emit('inputHandler')), ["enter"]))
                }, null, 40, _hoisted_5)), [
                  [_vModelDynamic, _ctx.data[_ctx.input.id]]
                ]),
        (_ctx.input.drop)
          ? (_openBlock(), _createBlock(_component_down_arrow_icon, {
              key: 5,
              class: _normalizeClass({ active: _ctx.input.showDrop }),
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('toggleDrop', _ctx.input)))
            }, null, 8, ["class"]))
          : _createCommentVNode("", true),
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (_ctx.input.drop && _ctx.input.showDrop)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.input.dropData, (element) => {
                      return (_openBlock(), _createElementBlock("li", {
                        onClick: ($event: any) => (_ctx.$emit('selectDropElement', element, _ctx.input)),
                        key: element._id,
                        clas: "flex items-center"
                      }, _toDisplayString(_ctx.dropElementName(element)), 9, _hoisted_7))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ], 4))
    : _createCommentVNode("", true)
}