
import { defineComponent, PropType } from "vue-demi";
import { Modal } from "@/interfaces/modal/modal.dto";
import CommonInput from "./Input.vue";

export default defineComponent({
  emits: ['inputHandler'],
  name: 'CommonInputs',
  props: {
    inputs: {
      type: Array as PropType<Array<Modal.Input>>,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    selectDropElement(element: { _id: string, name: string }, input: Modal.Input) {
      this.data[input.id] = element.name;
      this.data[input.id + '_id'] = element._id;
      input.showDrop = false;
    },
    inputKeyUp({ event, input }: { event: KeyboardEvent, input: Modal.Input }) {
      if (event.key === 'Tab') {
        this.inputs.forEach(i => i.showDrop = false);
        if (input.drop || input.dropBox) input.showDrop = true;
        return;
      }
    },
    toggleDrop(input: Modal.Input) {
      this.inputs.forEach(i => {
        if (i.id !== input.id) i.showDrop = false;
      });
      input.showDrop = !input.showDrop;
      if (input.showDrop) {
        this.jq("body").bind("click", (e) => {
          if (
            this.jq(e.target).closest(".drop").length === 0 &&
            this.jq(e.target).siblings(".drop").length === 0
          )
            input.showDrop = false;
        });
      } else {
        this.jq("body").unbind("click");
      }
    }
  },
  components: {
    CommonInput
  }
});
