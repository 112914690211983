
import { defineComponent } from "vue-demi";
import { useAPI } from '@/use';
import { RESET_INPUTS } from '@/store/common/auth';
import CommonInputs from '@/components/common/inputs/index.vue';
import { dynamicsObject } from "@/interfaces";

export default defineComponent({
  name: 'ResetMain',
  data() {
    return {
      auth: RESET_INPUTS,
      stages: [
        { action: this.reset, button: 'Отправить код на почту', message: 'Введите Ваш Email в поле для отправки письма с кодом подтверждения Вам на почту!' },
        { action: this.code, button: 'Проверить код', message: 'Для сброса пароля на Вашу почту было отправлено письмо с кодом, который необходимо ввести в поле!' },
        { action: this.change, button: 'Сбросить пароль', message: 'Введите Ваш новый пароль в поле для сброса текущего пароля!' },
      ],
      stage: null as dynamicsObject | null,
    }
  },
  created() {
    this.stage = this.stages[0];
  },
  methods: {
    async reset() {
      await useAPI().auth.resetMethod(this.auth.data);
      this.auth.inputs[1].show = true;
      this.stage = this.stages[1];
    },
    async code() {
      await useAPI().auth.checkCodeMethod(this.auth.data);
      this.auth.inputs[2].show = true;
      this.auth.inputs[3].show = true;
      this.stage = this.stages[2];
    },
    async change() {
      await useAPI().auth.changeMethod(this.auth.data);
      this.$router.push('/auth');
    },
    route(route: string) {
      this.$router.push(route);
    },
  },
  components: {
    CommonInputs
  }
});
