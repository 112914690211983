import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3af86552"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_common_input = _resolveComponent("common-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inputs, (input) => {
      return (_openBlock(), _createBlock(_component_common_input, {
        key: input.id,
        input: input,
        data: _ctx.data,
        onInputHandler: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('inputHandler'))),
        onToggleDrop: _ctx.toggleDrop,
        onInputKeyUp: _ctx.inputKeyUp,
        onSelectDropElement: _ctx.selectDropElement
      }, null, 8, ["input", "data", "onToggleDrop", "onInputKeyUp", "onSelectDropElement"]))
    }), 128))
  ]))
}