
import { defineComponent } from "vue-demi";
import ResetMain from "@/components/auth/Reset.vue";

export default defineComponent({
  name: "ResetView",
  components: {
    ResetMain,
  },
});
